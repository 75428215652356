import React, { useCallback, useEffect, useRef, useState } from "react"
import Layout from './layout'
import Header from './Header'


import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'

import { queryVideoData } from '../lib/API'
import { useData } from '../lib/hooks'
import moment from 'moment'
import Link from './Link'
import AudioImage from '../assets/images/audio.jpg' 

export default ( { url } ) => {

    return (
            <div className="video-container">
                <iframe width="600" height="400" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="video-main" src={url} />  
            </div>
    )


    
}

export const VideoThumbnail = ( { url, className } ) => {
    const youtubeId = url.split( "/embed/" )[1]
    const checkThumbnail = event => {
        const { target: node } = event
        if ( ( node.naturalWidth === 120 && node.naturalHeight === 90 ) ) {
            node.src = `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`
        }   
    }

    const thumbRef = ( node ) => {
        if ( node ) {
            // Check if a node is actually passed. Otherwise node would be null.
            // You can now do what you need to, addEventListeners, measure, etc.
            node.addEventListener( 'load', checkThumbnail )    
        } 
    }

    return <img ref={thumbRef} className={`video-thumb${className ? ' '+ className : ''}`} src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`} width="280" height="170" /> 


}

export const AudioPreview =  ( { title, url, audio, date, id, description } ) => {
    const momentDate = moment( date )
    const audioUrl = audio ? `https://admin.bcbchurch.co.uk${audio.url}` : url
    let audioElement
    const audioRef = ( node ) => {
        if( node ){
            node.addEventListener( 'play', event => {
                const audios = Array.from( document.getElementsByTagName( 'audio' ) ) 
                audios.forEach( audio => audio != event.target && audio.pause() )
            }, true )
            audioElement = node
        }
    }


    const play = () => {
        if( audioElement ) {
            if( !audioElement.paused ) {
                audioElement.pause()
            } else {
                audioElement.play()
            } 
        }
    }

    return (
        <div className="video-container" key={id} onClick={play}>
            <div className='grid-wrapper'>
                <div className='col-2'>
                    <img className='image' src={AudioImage} width="100" height="100" />

                    
                </div>
                <div className='col-6 info'>
                    <h3 className='video-title'>{title}</h3>
                    <p className='video-date'>{momentDate.format( "MMMM Do YYYY" )}</p>
                    <p className='video-description'>{description}</p>
                    <audio ref={audioRef} controls>

                        <source src={audioUrl || url} type="audio/mpeg" />
                        <source src={audioUrl || url} type="audio/wav" />
                        <source src={audioUrl || url} type="audio/ogg" />
                    </audio>
                </div>
            </div>
            {/* <a href='https://www.freepik.com/vectors/music'>Music vector created by rawpixel.com - www.freepik.com</a> */}
        </div>
    )
}
import React, { useCallback, useEffect, useRef, useState } from "react"


import { getVimeoThumbnail, queryVideoData } from '../lib/API'
import { useData } from '../lib/hooks'
import moment from 'moment'
import Link from './Link'
import { useScrollRestoration } from "gatsby"

export default ( { url } ) => {
    const videoStyle = {
        width: '85vw',
        height: '70vh'
    }
    return (
            <div className="video-container">
                <iframe style={videoStyle} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="video-main" src={url} />  
            </div>
    )


    
}

export const VideoThumbnail = ( { url, className } ) => {
    if( url.includes( "youtu" ) ) {
        let youtubeId = ''
        if( url.includes('v=') ){
            youtubeId = url.split('v=')[1].split('&')[0]
        }
        else if( url.includes('.be/') ){
            youtubeId = url.split('.be/')[1]
        }
        else{
            youtubeId = url.split( "/embed/" )[1]
        }

        const checkThumbnail = event => {
            const { target: node } = event
            if ( ( node.naturalWidth === 120 && node.naturalHeight === 90 ) ) {
                node.src = `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`
                // if ( ( node.naturalWidth === 120 && node.naturalHeight === 90 ) )
                //     node.src = `https://img.youtube.com/vi/${youtubeId}/default.jpg`

            }   
        }

        const thumbRef = ( node ) => {
            if ( node ) {
                // Check if a node is actually passed. Otherwise node would be null.
                // You can now do what you need to, addEventListeners, measure, etc.
                node.addEventListener( 'load', checkThumbnail )    
            } 
        }

        return <img ref={thumbRef} className={`video-thumb${className ? ' '+ className : ''}`} src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`} width="280" height="170" /> 
    } else {
        const id = url.split( "/" ).slice( -1 )
        return <VimeoThumbnail id={id} className={className} />
    }
}



export const VimeoThumbnail = ( { id, className } ) => {
    const [ src, setSrc ] = useState( null )
    const getThumbnail = async () => {
        const data = await getVimeoThumbnail( id )
        setSrc( data.thumbnail_large.replace( 'http://','https://' ) )

    }

    useEffect( () => {
        getThumbnail()
    }, [] )


    return <img className={`video-thumb${className ? ' '+ className : ''}`} src={src} width="280" height="170" /> 

}

export const VideoPreview =  ( { title, url, date, id, description } ) => {
    const momentDate = moment( date )
    if( url.includes( 'youtu' ) ){ // Handles YouTube links
        return (
            <div className="video-container" key={id}>
                <Link id={`video${id}`} to={`/media/videos/${id}`} className="video-link" >
                    <div className='grid-wrapper'>
                        <div className='col-6'>

                            <VideoThumbnail url={url} />
                            {/* <img ref={thumbRef} className="video-thumb" src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`} width="280" height="170" />  */}
                        </div>
                        <div className='col-6 info'>
                            <h3 className='video-title'>{title}</h3>
                            <p className='video-date'>{momentDate.format( "MMMM Do YYYY" )}</p>
                            <p className='video-description'>{description}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    } else {
        let finalUrl = url
        if( !finalUrl.includes( 'player' ) ) {
            finalUrl = `https://player.vimeo.com/video/${finalUrl.split( "/" ).slice( -1 )}`
        }
        const vimeoId = finalUrl.split( "/video/" )[1] 
        return (
            <div className="video-container" key={id}>
                <Link id={`video${id}`} to={`/media/videos/${id}`} className="video-link" >
                    <div className='grid-wrapper'>
                        <div className='col-6'>
                            <VimeoThumbnail id={vimeoId} />
                            {/* <img ref={thumbRef} className="video-thumb" src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`} width="280" height="170" />  */}
                        </div>
                        <div className='col-6 info'>
                            <h3 className='video-title'>{title}</h3>
                            <p className='video-date'>{momentDate.format( "MMMM Do YYYY" )}</p>
                            <p className='video-description'>{description}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}
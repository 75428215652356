import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import DOMPurify from 'dompurify'
import Layout from '../components/layout'
import Header from '../components/Header'


import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'

import { queryVideoData } from '../lib/API'
import { useData } from '../lib/hooks'
import moment from 'moment'
import Video from '../components/Video'


export default ( { id } ) => {
    const purifyInstance = typeof window !== 'undefined' ? DOMPurify( window ) : { sanitize : str => str }
    const data = useData( queryVideoData, { id: purifyInstance.sanitize( id ) } )

    const siteTitle = "Blackheath and Charlton Baptist Church"
    const { title, description, url, date, sermon_by } = data[0] || { }
    const momentDate = moment( date )

    let finalUrl = url || ""
    if( finalUrl.includes( "vimeo" ) && !finalUrl.includes( 'player' ) ) {
        finalUrl = `https://player.vimeo.com/video/${finalUrl.split( "/" ).slice( -1 )}`
    }
    else if( finalUrl.includes('v=') ){
        finalUrl = `https://youtube.com/embed/${url.split('v=')[1].split('&')[0]}`
    }
    else if( finalUrl.includes('.be/') ){
        finalUrl = `https://youtube.com/embed/${url.split('.be')[1]}`
    }

    useEffect( () => {

        setTimeout( () => document.getElementById( id ).scrollIntoView(), 200 )
        
    }, [] )

    return (
        <div id='video-play-page'>
            <Helmet title={siteTitle} />
            <section id={id} className="container main style1 special">
                <header className="major">
                    <h2 className='video-title'>{title}</h2>
                    {sermon_by && <h3 className='video-date'>By {sermon_by}</h3>}
                    <h3 className='video-date'>{momentDate.format( "MMMM Do YYYY" )}</h3>
                </header>
                <Video url={finalUrl} />
                <p className='video-description'>{description}</p>
            </section>
        </div>
    )
}